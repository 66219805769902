<template>
  <p>Compteur :{{ count }}</p>
  <button v-on:click="increment">Incrémenter</button>
</template>

<script setup>
import { ref } from 'vue';

const count = ref(0);
const increment = () => count.value++;
</script>

<style>

</style>
